import { render, staticRenderFns } from "./FormSurvey.vue?vue&type=template&id=3d3b0e06&scoped=true&"
import script from "./FormSurvey.vue?vue&type=script&lang=js&"
export * from "./FormSurvey.vue?vue&type=script&lang=js&"
import style0 from "./FormSurvey.vue?vue&type=style&index=0&id=3d3b0e06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d3b0e06",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import TouchHold from 'quasar/src/directives/TouchHold.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QBtn,QSelect,QInput,QCheckbox});qInstall(component, 'directives', {ClosePopup,TouchHold});
